<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/136.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD PCB HOLDER
 
 
 


                </h4>
                <h5 style="text-align: center">SINT-311</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/137.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD PCB HOLDER
 




                </h4>
                <h5 style="text-align: center">SINT-312</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/138.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD PCB HOLDER
 
 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-313</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/139.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD PCB HOLDER
 
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-314
                </h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
        <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/140.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                ESD PCB HOLDER
 

 


                </h4>
                <h5 style="text-align: center">SINT-315</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/141.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD PCB HOLDER





                </h4>
                <h5 style="text-align: center">SINT-316</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/142.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGAZINE RACK
 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-321</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/143.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD MAGAZINE RACK
 
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-322</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
        <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/144.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGAZINE RACK
 
 
 


                </h4>
                <h5 style="text-align: center">SINT-323</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/145.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGAZINE RACK
 




                </h4>
                <h5 style="text-align: center">SINT-324</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/146.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGAZINE RACK
 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-325</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 50% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/147.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD MAGAZINE RACK
 
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-326</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/148.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGAZINE RACK
 
 
 


                </h4>
                <h5 style="text-align: center">SINT-327</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/hloderrack">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/149.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ESD MAGAZINE RACK
 




                </h4>
                <h5 style="text-align: center">SINT-328</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      
      </div>
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>